import React, {Fragment, useEffect, useState} from "react";
import {Button, Grid, Paper, TextField, Typography} from "@material-ui/core";
// import {CustomRouterLink} from "../../../../shared/utility";
import * as yup from "yup";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import main from '../../style/main'
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../../../components/UI";

const schema = yup.object().shape({
  code: yup.string().required("Gold Code harus diisi"),
});


const FormProductCheck = props => {
  const classes = main();
  const {
    onProductCheck,
    loading
  } = props;
  const history = useHistory();

  useEffect(() => {
  //   onClearErrorAuth()
  }, [])

  const [formState, setFormState] = useState({
    code: ''
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if (event.target.type === 'checkbox') {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    } else {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    console.log(data);
    onProductCheck(data.code, history)
    // onStoreUser(data,history);
  }
  // console.log(errors)

  return (loading ? <Loading/> :
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <img src={require('../../../../assets/images/logo/Logo-EOA-Scanner.png').default} alt="club" className={classes.logoClub} />
                  </Grid>
                  {/* <Grid item>
									<img src={require('../../assets/images/logo/Logo PT.png').default} alt="club" className={classes.logoClub}/>
								</Grid> */}

                </Grid>
                <div>
                  <Typography variant={"h3"} style={{ fontWeight: "bold", marginTop: "10px" }}>
                    Cek Keaslian Produk <br/>EOA GOLD
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                      <TextField
                        variant={"standard"}
                        defaultValue={formState.code}
                        margin="normal"
                        fullWidth
                        id="code"
                        label="Gold Code"
                        name="code"
                        autoComplete="code"
                        autoFocus
                        inputRef={register}
                        onChange={handleChange}
                        error={errors.code ? true : false}
                        helperText={errors.code && errors.code.message}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Submit
                      </Button>
                    </form>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = state => {
   return {
     loading: state.scan.loading
   }
}

const mapDispatchToProps = dispatch => {
  return {
    onProductCheck: (code, history) => dispatch(actions.productCheck(code, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProductCheck);