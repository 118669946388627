import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import {SignIn as SignInView} from 'views';
// import { Minimal as MinimalLayout } from 'layouts';
import * as actions from '../../store/actions';
import {connect} from "react-redux";


const RouteWithLayout = props => {
  const { onSetAlert, layout: Layout, component: Component, ...rest } = props;
  let user = JSON.parse(sessionStorage.getItem("user"))
  if(user){
    if(user.platform === 'mobile'){
      onSetAlert('Tidak Memilki Akses', 'error');
      sessionStorage.clear()
    }
  }
  // (user ? user.platform === 'mobile' : sessionStorage.clear())
  const access_token = sessionStorage.getItem('access_token');

  return (
    <Route
      {...rest}
      render={matchProps => (
        access_token ?
        <Layout>
          <Component {...matchProps} />
        </Layout>
        : (
          <Redirect to="/sign-in"
          />
        )
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
    onSetAlert: (msg,status) => dispatch(actions.setAlert(msg, status))
  }
}

export default connect(null,mapDispatchToProps)(RouteWithLayout);
