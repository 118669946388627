import React,{Fragment} from "react";
import {main}  from './style';
import {Breadcrumbs, Grid, Link, Typography} from "@material-ui/core";
import {Form} from "./component";

const UploadDocument = props => {
  const classes = main();

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div>
                <div className={classes.tagMenu}>
                  Upload Document
                </div>
                <div>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/dashboard">
                      Dashboard
                    </Link>
                    <Typography color="textPrimary">Upload Document</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Form classes={classes}/>
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDocument;