import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Logo from '../img/login-logo.png';
import { makeStyles } from "@material-ui/core/styles";
import {
	Button,
	Paper
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useHistory } from 'react-router-dom';

// import { addLogin } from '../../actions/login'
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { CustomRouterLink } from "../../shared/utility";
import { Loading } from "../../components/UI";
import {LockOpenRounded, LockRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundColor: '#011747',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '30px 50px',
		fontSize: 16,
		textAlign: 'center',
	},
	avatar: {
		marginTop: theme.spacing(8),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		// marginTop: 20,
		paddingTop: 25
	},
	textField: {
		[`& fieldset`]: {
			borderRadius: 100,
		},
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: 'none',
		backgroundColor: theme.palette.warning.main,
		'&:hover': {
			backgroundColor: theme.palette.warning.light
		},
		color: '#FFFFFF',
		// borderRadius: 100
	},
	btnText: {
		textTransform: "none"
	},
	contentName: {
		// padding: '200px 20px 0px',
		padding: theme.spacing(25, 6)
	},
	center: {
		color: '#FFFFFF',
		fontSize: 50,
		paddingBottom: 15
	},
	center2: {
		color: '#FFFFFF',
		fontSize: 60
	},
	logoClub: {
		width: 113,
		height: 'auto',
	},
	welcome: {
		fontSize: 20,
		fontWeight: 'bold',
		paddingBottom: 10,
	},
	btnForget: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		marginBottom: 15,
	},
	footer: {
		fontSize: 14
	}
}))

const SignInSchema = yup.object().shape({
	email: yup.string().required("Email harus diisi"),
	password: yup.string().required("Password harus diisi"),
});

const Login = props => {
	const classes = useStyles();
	const history = useHistory();
	const {
		errors,
		loading,
		onClearErrorAuth
	} = props;
	const { register, handleSubmit } = useForm({
		resolver: yupResolver(SignInSchema)
	});

	useEffect(() => {
		onClearErrorAuth()
	}, [onClearErrorAuth])

	const onSubmit = event => {
		props.onAuth(event.email, event.password, history)
	}

	const [values, setValues] = React.useState({
		showPassword: false,
	});

	// const handleChange = (prop) => (event) => {
	//   setValues({ ...values, [prop]: event.target.value });
	// };

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (loading ? <Loading /> :
		<Grid container>
			<Grid item lg={12} md={12} sm={12} xs={12}>
				<Grid container>
					<Grid item lg={3} md={3} sm={12} xs={12} />
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Paper className={classes.paper}>
							<Grid container justify={"center"}>
								<Grid item>
									<img src={require('../../assets/images/logo/Logo-EOA-Scanner.png').default} alt="club" className={classes.logoClub} />
								</Grid>
								{/* <Grid item>
									<img src={require('../../assets/images/logo/Logo PT.png').default} alt="club" className={classes.logoClub}/>
								</Grid> */}

							</Grid>
							<div>
								<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										autoFocus
										inputRef={register}
										error={errors.email ? true : false}
										helperText={errors.email && errors.email[0]}
									// className={classes.textField}
									/>
									{/* <TextField
										variant="outlined"
										margin="normal"
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										inputRef={register}
										error={errors.password ? true : false}
										helperText={errors.password && errors.password[0]}
									// className={classes.textField}
									/> */}

									<TextField
										label="Password"
										id="outlined-start-adornment"
										InputProps={{
											endAdornment:
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{values.showPassword ? <LockOpenRounded /> : <LockRounded />}
													</IconButton>
												</InputAdornment>,
										}}
										variant="outlined"
										margin="normal"
										fullWidth
										name="password"
										type={values.showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										inputRef={register}
										error={errors.password ? true : false}
										helperText={errors.password && errors.password[0]}
									/>
									<Grid container justify={"flex-end"}>
										<Grid item>
											<Button
												variant={"text"}
												className={classes.btnText}
												component={CustomRouterLink}
												to={'form-forgot-password'}
											>
												Lupa Password ?
											</Button>
										</Grid>
									</Grid>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										Log In
									</Button>
								</form>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	return {
		errors: state.auth.errors,
		loading: state.auth.loading,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAuth: (email, password, history) => dispatch(actions.auth(email, password, history)),
		onClearErrorAuth: () => dispatch(actions.clearErrorAuth())
		// onAlert: (message, alertType) => dispatch(actions.setAlert(message, alertType))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);