import {makeStyles} from "@material-ui/core";

const Main = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundColor: '#011747',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 50px',
    fontSize: 16,
    textAlign: 'center',
  },
  avatar: {
    marginTop: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: 20,
    paddingTop: 25
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 100,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    },
    color: '#FFFFFF',
    // borderRadius: 20
  },
  btnText: {
    textTransform: "none",
    color: '#2D9CDB'
  },
  contentName: {
    // padding: '200px 20px 0px',
    padding: theme.spacing(25, 6)
  },
  center: {
    color: '#FFFFFF',
    fontSize: 50,
    paddingBottom: 15
  },
  center2: {
    color: '#FFFFFF',
    fontSize: 60
  },
  logoClub: {
    width: 113,
    height: 'auto',
  },
  welcome: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  btnForget: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 15,
  },
  footer: {
    fontSize: 14
  },
  textToLogin: {
    marginTop: theme.spacing(3)
  },
  textWarning: {
    color: theme.palette.warning.main
  }
}));

export default Main;