import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const authStart = () => {
  return {
    type: actions.AUTH_START
  }
}

export const authSuccess = (token, userId, userData) => {
  return {
    type: actions.AUTH_SUCCESS,
    tokenId: token,
    userId: userId,
    userData: userData
  }
}

export const authFail = (errors) => {
  return {
    type: actions.AUTH_FAIL,
    errors: errors
  }
}

export const auth = (email, password, history) => {
  return dispatch => {
    dispatch(authStart())
    const authData = {
      email: email,
      password: password
    }

    axios.post('api/auth/login', authData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        sessionStorage.setItem('access_token', response.data.token.access_token)
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        history.push(`/dashboard`);
        dispatch(authSuccess(response.data.token.access_token, response.data.user))
      })
      .catch(err => {
        dispatch(authFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const resetPasswordStart = () => {
  return {
    type: actions.RESET_PASSWORD_START
  }
}

export const resetPasswordSuccess = () => {
  return {
    type: actions.RESET_PASSWORD_SUCCESS,
  }
}

export const resetPasswordFail = (errors) => {
  return {
    type: actions.RESET_PASSWORD_FAIL,
    errors: errors
  }
}

export const resetPassword = (storeData, history) => {
  return dispatch => {
    dispatch(resetPasswordStart())
    axios.post('api/auth/forgot', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        history.push(`/sign-in`);
        dispatch(resetPasswordSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
      })
      .catch(err => {
        dispatch(resetPasswordFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const changePasswordStart = () => {
  return {
    type: actions.CHANGE_PASSWORD_START
  }
}

export const changePasswordSuccess = () => {
  return {
    type: actions.CHANGE_PASSWORD_SUCCESS,
  }
}

export const changePasswordFail = (errors) => {
  return {
    type: actions.CHANGE_PASSWORD_FAIL,
    errors: errors
  }
}

export const changePassword = (storeData, history) => {
  return dispatch => {
    dispatch(changePasswordStart())
    axios.patch('api/auth/reset', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        history.push(`/sign-in`);
        dispatch(changePasswordSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
      })
      .catch(err => {
        dispatch(changePasswordFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const authCheck = () => {
  return dispatch => {
    const token = sessionStorage.getItem('access_token')
    const user = JSON.parse(sessionStorage.getItem('user'))
    if (!token) {
      sessionStorage.clear()
    } else {
      // const data = JSON.parse(sessionStorage.getItem('data'))
      if (!(user)) {
        sessionStorage.clear()
      } else {
        dispatch({
          type: actions.AUTH_CHECK,
          user: user,
          token: token
        })
      }
    }
  }
}

export const clearErrorAuth = () => {
  return {
    type: actions.CLEAR_ERROR_AUTH
  }
}