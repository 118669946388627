import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper, TextField, Typography
} from "@material-ui/core";

import main from '../../style/main';
import * as actions from '../../../../store/actions';
import { connect } from "react-redux";
import { Loading } from "../../../../components/UI";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory } from 'react-router-dom';
import { CustomRouterLink } from "../../../../shared/utility";

const schema = yup.object().shape({
});

const FormForgotPasword = props => {
  const classes = main();
  const {
    onResetPassword,
    loading,
    errors,
    onClearErrorAuth
  } = props;
  const history = useHistory();

  useEffect(() => {
    onClearErrorAuth()
  }, [onClearErrorAuth])

  const [formState, setFormState] = useState({
    email: ''
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if (event.target.type === 'checkbox') {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    } else {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // console.log(data);
    onResetPassword(data, history)
    // onStoreUser(data,history);
  }

  return (loading ? <Loading /> :
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
                    Lupa Password ?
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                      <TextField
                        variant="outlined"
                        defaultValue={formState.email}
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        inputRef={register}
                        onChange={handleChange}
                        error={errors.email ? true : false}
                        helperText={errors.email && errors.email[0]}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Reset Password
                      </Button>
                    </form>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <hr />
                    <div className={classes.textToLogin}>
                      Kembali ke halaman login ? <Button
                        variant={"text"}
                        className={classes.btnText}
                        component={CustomRouterLink}
                        to={'sign-in'}
                        style={{ display: 'inline' }}
                      >
                        Log in
                      </Button>
                    </div>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    errors: state.auth.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetPassword: (storeData, history) => dispatch(actions.resetPassword(storeData, history)),
    onClearErrorAuth: () => dispatch(actions.clearErrorAuth())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormForgotPasword);