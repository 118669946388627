import React, { Fragment, useState, useEffect } from "react";
import main from '../../style/main';
import { Button, Grid, Paper, TextField, Typography, FormControl, FormHelperText } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  useParams
} from 'react-router-dom';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory } from 'react-router-dom';
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { Loading } from "../../../../components/UI";
import {LockOpenRounded, LockRounded} from "@material-ui/icons";

const schema = yup.object().shape({
});

const ChangePassword = props => {
  const classes = main();
  const { token } = useParams();
  const history = useHistory();
  const {
    onChangePassword,
    loading,
    errors,
    onClearErrorAuth
  } = props;

  useEffect(() => {
    onClearErrorAuth()
  }, [onClearErrorAuth])

  const [formState, setFormState] = useState({
    password: '',
    confirm_password: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setFormState({ ...formState, showPassword: !formState.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setFormState({ ...formState, showConfirmPassword: !formState.showConfirmPassword });
  };

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if (event.target.type === 'checkbox') {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    } else {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    data.token = token;
    onChangePassword(data, history)
    // onStoreUser(data,history);
  }
  return (loading ? <Loading /> :
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
                    Ubah Password
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type={formState.showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        defaultValue={formState.password}
                        inputRef={register}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {formState.showPassword ? <LockOpenRounded /> : <LockRounded />}
                              </IconButton>
                            </InputAdornment>,
                        }}
                        error={errors.password ? true : false}
                        helperText={errors.password && errors.password[0]}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="confirm_password"
                        label="Confirm Password"
                        type={formState.showConfirmPassword ? 'text' : 'password'}
                        // id="password"
                        autoComplete="current-password"
                        defaultValue={formState.confirm_password}
                        inputRef={register}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {formState.showConfirmPassword ? <LockOpenRounded /> : <LockRounded />}
                              </IconButton>
                            </InputAdornment>,
                        }}
                        error={errors.confirm_password ? true : false}
                        helperText={errors.confirm_password && errors.confirm_password[0]}
                      />

                      <FormControl
                        error={errors.token ? true : false}
                        fullWidth
                      >
                        <FormHelperText>{errors.token && errors.token[0]}</FormHelperText>
                      </FormControl>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Ubah Password
                      </Button>
                    </form>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    errors: state.auth.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangePassword: (storeData, history) => dispatch(actions.changePassword(storeData, history)),
    onClearErrorAuth: () => dispatch(actions.clearErrorAuth())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);