import React, {Fragment, useEffect} from 'react';
// import {Link} from 'react-router-dom';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import {Avatar} from '@material-ui/core';

import { connect } from 'react-redux'
import Paper from "@material-ui/core/Paper";
// import { getProfile } from '../../../../actions/profile'

// const nameColorBlack = '#000000';
import * as actions from '../../../../store/actions'
import {CustomRouterLink} from "../../../../shared/utility";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    margin: theme.spacing(10,2,2),
    // margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2,0),
    },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.main
  },
  paperMargin: {
    margin: theme.spacing(10, 2, 2),
    backgroundColor: theme.palette.warning.main
  },
  avatar: {
    width: '56px',
    height: '56px'
  },
  name: {
    // marginTop: theme.spacing(1),
    // // textAlign: 'center',
    // color: nameColorWhite,
    // fontFamily: 'Nunito'
    fontSize: '16px',
    fontWeight: 600,
    textAlign: "center"
  },
  email: {
    fontSize: '14px',
    textAlign: "center"
  },
  profileText: {
    color: theme.palette.white,
    width: '100%',
    wordBreak: "break-word",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

const Profile = props => {
  const classes = useStyles();
  // const data = JSON.parse(sessionStorage.getItem('user'))
  const {
    onGetProfile,
    profile,
    changing
  } = props;

  useEffect(() => {
    onGetProfile();
  },[onGetProfile, changing]);

  return (
    <Fragment>
      <Paper className={classes.root} component={CustomRouterLink} to={"/profile"}>
        {/*<Grid container justify={"center"} spacing={1} alignItems={"center"}>*/}
          {/*<Grid item xl={12} md={12} xs={12} sm={12}>*/}
          {/*<Grid item xl={4} md={4} xs={4} sm={4}>*/}
          {/*  <Avatar*/}
          {/*    alt="Person"*/}
          {/*    className={classes.avatar}*/}
          {/*    component={RouterLink}*/}
          {/*    // src={profile.image}*/}
          {/*    src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`}*/}
          {/*    to="/profile"*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xl={12} md={12} xs={12} sm={12}>*/}
            <div className={classes.profileText} to={'/profile'}>
              {/*<div>*/}
              {/*  <Avatar*/}
              {/*    alt="Person"*/}
              {/*    className={classes.avatar}*/}
              {/*    component={RouterLink}*/}
              {/*    // src={profile.image}*/}
              {/*    src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`}*/}
              {/*    to="/profile"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className={classes.name}>
                {profile.name}
              </div>
              {/*<div className={classes.email}>*/}
              {/*  {profile.email}*/}
              {/*</div>*/}
            </div>
          {/*</Grid>*/}
        {/*</Grid>*/}
      </Paper>
    </Fragment>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    profile: state.profile.user,
    changing: state.profile.changing
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetProfile: () => dispatch(actions.getProfile())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile)
