import React, {useEffect} from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Login } from './layouts';

import {
  Dashboard as DashboardView,
  ManagementUser as ManagementUserView,
  ManagementUserCreate as ManagementUserCreateView,
  ManagementUserUpdate as ManagementUserUpdateView,
  Profile as ProfileView,
  UploadDocument as UploadDocumentView,
  Vendor as VendorView,
  VendorCreate as VendorCreateView,
  Product as ProductView,
  ProductCreate as ProductCreateView,
  ProductUpdate as ProductUpdateView,
  FormProductCheck as FormProductCheckView,
  ProductCheckDetail as ProductCheckDetailView,
  ProductCheckFailed as ProductCheckFailedView
} from './views';

import {
  ChangePassword,
  FormForgotPassword
} from './layouts/ForgotPassword'
import {connect} from "react-redux";
import * as actions from './store/actions';

const Routes = (props) => {

  // const user = JSON.parse(sessionStorage.getItem('user'));
  const {
    user,
    onAuthCheck
  } = props;

  useEffect(() => {
    onAuthCheck()
  },[onAuthCheck]);

  return (
    <Switch>
      {console.log(user.role)}
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={ProfileView}

        layout={MainLayout}
        path="/profile"
      />

      <RouteWithLayout
        component={UploadDocumentView}

        layout={MainLayout}
        path="/upload-document"
      />

      <RouteWithLayout
        component={VendorView}

        layout={MainLayout}
        path="/vendor"
      />

      <RouteWithLayout
        component={VendorCreateView}

        layout={MainLayout}
        path="/vendor-create"
      />

      <RouteWithLayout
        component={ProductUpdateView}

        layout={MainLayout}
        path="/product/:id"
      />

      <RouteWithLayout
        component={ProductView}

        layout={MainLayout}
        path="/product"
      />

      <RouteWithLayout
        component={ProductCreateView}

        layout={MainLayout}
        path="/product-create"
      />

      <Route
        component={Login}
        exact
        // layout={Login}
        path="/sign-in"
      />

      <Route
        component={FormForgotPassword}
        exact
        // layout={Login}
        path="/form-forgot-password"
      />

      <Route
        component={ChangePassword}
        exact
        // layout={Login}
        path="/change-password/:token"
      />

      <Route
        component={ProductCheckDetailView}
        exact
        // layout={Login}
        path="/product-check-detail/:id"
      />

      <Route
        component={FormProductCheckView}
        exact
        // layout={Login}
        path="/form-product-check"
      />

      <Route
        component={ProductCheckFailedView}
        exact
        // layout={Login}
        path="/product-check-fail"
      />

      {user && user.role === 'super_admin' && (
        <RouteWithLayout
          component={ManagementUserUpdateView}

          layout={MainLayout}
          path="/management-user/:id"
        />
      )}

      {user && user.role === 'super_admin' && (
        <RouteWithLayout
          component={ManagementUserView}

          layout={MainLayout}
          path="/management-user"
        />
      )}

      {user && user.role === 'super_admin' && (
        <RouteWithLayout
          component={ManagementUserCreateView}

          layout={MainLayout}
          path="/management-user-create"
        />
      )}

      <Redirect to="/"/>
    </Switch>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuthCheck: () => dispatch(actions.authCheck())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Routes);
