import React, {Fragment, useEffect} from "react";
import {useParams} from 'react-router-dom';
import {connect} from "react-redux";
import * as actions from '../../../../store/actions';
import {Loading} from "../../../../components/UI";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {CustomRouterLink, isEmpty} from "../../../../shared/utility";
import main from "../../style/main";

const ProductCheckDetail = props => {
  const classes = main();
  const {id} = useParams();
  const {
    onGetProductDetail,
    loading,
    scan
  } = props;

  useEffect(() => {
    onGetProductDetail(id)
  },[onGetProductDetail, id])

  console.log(scan);
  let scanData = '';
  if(!isEmpty(scan)){

    scanData = (
      <Fragment>
        <table>
          <tr>
            <td>
              Gold Code
            </td>
            <td>
              : <span className={classes.textWarning}>{scan.code}</span>
            </td>
          </tr>
          <tr>
            <td>
              Produk
            </td>
            <td>
              : <span className={classes.textWarning}>{scan.gold_name}</span>
            </td>
          </tr>
          <tr>
            <td>
              Berat
            </td>
            <td>
              : <span className={classes.textWarning}>{scan.gold_gram} Gram</span>
            </td>
          </tr>
        </table>
      </Fragment>
    );
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <img src={require('../../../../assets/images/logo/Logo-EOA-Scanner.png').default} alt="club" className={classes.logoClub} />
                  </Grid>

                </Grid>
                <div>
                  <CheckCircleIcon style={{marginTop: "10px", fontSize:"50px", color: 'green'}}/>
                </div>
                <div>
                  <Typography variant={"h4"} style={{marginTop: "10px" }}>
                    ALHAMDULILLAH ASLI
                  </Typography>
                </div>
                <Grid container justify={"center"}>
                  <Grid item>
                    <div style={{marginTop: "10px",textAlign: "justify"}}>
                      {scanData}
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      component={CustomRouterLink}
                      to={'/form-product-check'}
                      className={classes.submit}
                    >
                      Cek Ulang
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.scan.loading,
    scan: state.scan.scan.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProductDetail: (id) => dispatch(actions.productDetail(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCheckDetail);